import React, { ChangeEvent } from 'react';

function InputFile({ label, onChange, multiple, disabled, className = '' } : {
    label: React.ReactNode,
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void,
    multiple?: boolean
    disabled?: boolean
    className?: string
}) {
    return (
        <label className={ `ant-btn ant-btn-primary ant-btn-background-ghost ${className}` }>
            <span>{ label }</span>
            <input type="file" style={ {display: 'none'} }
                disabled={ disabled }
                multiple={ multiple }
                onChange={ onChange }
            />
        </label>
    );
}

InputFile.displayName = 'InputFile';

export default InputFile;

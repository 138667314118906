import { useState, useEffect } from 'react';
import GetFlattened from './../helpers/GetFlattened';
import SetFlattened from './../helpers/SetFlattened';
import AlertService from './../services/AlertService';
import { RestInterface } from '../services/Rest';
import { StateLinkable } from '../Components/Interfaces';
import { AxiosResponse, AxiosError } from 'axios';

type ApiRelationsResponse = {
    [key: string]: any[]
};

type ErrorsType = {
    hasErrors: boolean,
    [key: string]: any
};

export default function useForm<Model>(
    Service: RestInterface,
    params: Record<string, any> = {id: null},
    options: {
        onDataFetch?: Function
        onRelationsFetch?: Function
        fetchRelations?: Boolean
        afterSave?: (res: AxiosResponse) => void
    } = {},
    initialState: Model,
) : {
    state: Model,
    loading: boolean,
    errors: ErrorsType,
    relations: ApiRelationsResponse,
    link: ((field: string) => StateLinkable),
    getError: Function,
    changeStateField: Function,
    onSubmit: Function,
} {
    const initialErrors = {hasErrors: false};
    const [state, setState]  = useState<Model>(initialState);
    const [relations, setRelations]  = useState({});
    const [errors, setErrors]  = useState<ErrorsType>(initialErrors);
    const [loading, setLoading]  = useState(false);

    useEffect(() => {
        function handleFindResponse(res: AxiosResponse, formRelations: any) {
            let data = typeof options.onDataFetch === 'function'
                ? options.onDataFetch(res.data, formRelations !== false ? formRelations.data : formRelations)
                : res.data;
            return data;
        }

        function handleRelationsResponse(res: AxiosResponse) {
            let data = typeof options.onRelationsFetch === 'function'
                ? options.onRelationsFetch(res.data)
                : res.data;
            return data;
        }

        function fetchResource() {
            let calls: any[] = [false, false];
            if (params.id) {
                calls[0] = Service.find(params.id);
            }
            if (options.fetchRelations === true) {
                calls[1] = Service.getFormRelations();
            }

            if (calls[0] || calls[1]) {
                setLoading(true);
                Promise.all(calls)
                .then((results: AxiosResponse[]) => {
                    if (results[0]) {
                        const newState = Object.assign({}, initialState, handleFindResponse(results[0], results[1]));
                        setState(newState);
                    }
                    if (results[1]) {
                        setRelations(
                            handleRelationsResponse(results[1])
                        );
                    }
                    // this.setState(() => {return newState;});
                    setLoading(false);
                })
                .catch(() => {
                    setLoading(false);
                });
            }
        }
        fetchResource();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    function getError(field: string) {
        if (errors[field] !== undefined) {
            for (const prop in errors[field]) {
                return errors[field][prop];
            }
        }
        return null;
    }

    function changeStateField(field: string, value: any) {
        const newState = SetFlattened<Model>(field, value, { ...state });
        setState(newState);
    }

    function link(field: string) {
        return {
            name: field,
            value: GetFlattened(field, state, ''),
            onChange: (e: any) => {
                changeStateField(field, e.target.value);
            }
        };
    }

    function onSubmit(data: Model) {
        setLoading(true);
        setErrors(initialErrors);
        Service.save(data || state)
        .then((res: AxiosResponse) => {
            if (typeof options.afterSave === 'function') {
                options.afterSave(res);
            }
            setLoading(false);
        })
        .catch((error: AxiosError) => {
            setLoading(false);
            if (!error.response) {
                return;
            }
            switch (error.response.status) {
                case 422:
                    AlertService.error('Ops, verifique todos os campos.');
                    setErrors(Object.assign({hasErrors: true}, error.response.data.error_data));
                    break;
                default:
                    break;
            }
        });
    }

    return {
        state,
        loading,
        errors,
        relations,
        link,
        getError,
        changeStateField,
        onSubmit,
    };
}

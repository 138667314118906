import { ReactNode } from 'react';
import { Button, Col, Descriptions, Row, Spin, Tooltip } from 'antd';
import {
  LinkedinOutlined,
  FilePdfOutlined
} from '@ant-design/icons';
import CandidateService, { CandidateModel } from '../../services/CandidateService';
import useView from '../../hooks/useView';
import MysqlToBRDate from '../../helpers/MysqlToBRDate';
import MaskCEP from '../../helpers/MaskCEP';
import OccupationService from '../../services/OccupationService';
import CandidateLanguageService from '../../services/CandidateLanguageService';
import AcademicTitleService from '../../services/AcademicTitleService';
import { PhoneMask } from '../../helpers/MaskPhone';
import AuthService from '../../services/AuthService';

export default function PrintView({ params, querystring }: any) {
    const { state, loading } = useView<CandidateModel>(
        CandidateService,
        params,
        {},
        {
            occupation_id: 1,
            languages: [],
            academic_titles: [],
            previous_jobs: [],
            interest_areas: [],
        }
    );
    if (loading) {
        return <div className="text-center">
            <Spin />
        </div>
    }

    const birthdate = state.birthdate ? (
        MysqlToBRDate(state.birthdate)
    ) : '-';
    const linkedin_url = state.linkedin_url ? (
      <Tooltip placement="top" title="Acessa o linkedin">
        <Button type="link" href={ state.linkedin_url } target="_blank" rel="noopener noreferrer">
            { state.linkedin_url } <LinkedinOutlined />
        </Button>
      </Tooltip>
    ) : '-';
    let academic_titles: ReactNode = '-';
    if (state.academic_titles.length) {
        academic_titles = (
            <ul>
                { state.academic_titles.map((titles) => {
                    return <li key={ titles.id }>{ `${titles.institution} - ${AcademicTitleService.TYPES[titles.academic_title_type_id].name}` }</li>
                }) }
            </ul>
        );
    }
    let languages: ReactNode = '-';
    if (state.languages.length) {
        languages = (
            <ul>
                { state.languages.map((language) => {
                    return <li key={ language.id }>{ `${language.name} - ${CandidateLanguageService.MAP[language.level].name}` }</li>
                }) }
            </ul>
        );
    }
    let interest_areas: ReactNode = '-';
    if (state.interest_areas.length) {
        interest_areas = (
            <ul>
                { state.interest_areas.map((area) => {
                    return <li key={ area.id }>{area.name} - <strong>{area.pivot.years} anos</strong></li>
                }) }
            </ul>
        );
    }
    let previous_jobs: ReactNode = '-';
    if (state.interest_areas.length) {
        previous_jobs = state.previous_jobs.map((pj, i) => {
            let space = i > 0 ? <Descriptions.Item span={ 3 }>&nbsp;</Descriptions.Item> : null;
            return (
                <div key={ pj.id }>
                    <br />
                    <Descriptions size="small" title="Experiências profissionais" bordered>
                        { space }
                        <Descriptions.Item label={ <strong>Instituição</strong> }> { pj.institution }</Descriptions.Item>
                        <Descriptions.Item label={ <strong>Cargo</strong> }> { pj.role }</Descriptions.Item>
                        <Descriptions.Item label={ <strong>Cidade</strong> }> { `${pj.city?.name} - ${pj.state?.uf}` }</Descriptions.Item>
                        <Descriptions.Item label={ <strong>Responsabilidades</strong> } span={ 3 }> { pj.responsabilities }</Descriptions.Item>
                        <Descriptions.Item label={ <strong>Habilidades</strong> } span={ 3 }> { pj.habilities }</Descriptions.Item>
                        <Descriptions.Item label={ <strong>Resultados</strong> } span={ 3 }> { pj.results }</Descriptions.Item>
                    </Descriptions>
                </div>
            )
        });
    }

    let curriculum: ReactNode = '-';
    if (state.curriculum) {
      curriculum = (
        <span>
          <Tooltip placement="top" title="Visualizar currículo">
            <Button type="link" shape="circle" href={ `${state.curriculum.url}?token=${AuthService.getToken()}` } target="_blank" rel="noopener noreferrer">
              { state.curriculum.name }
              <FilePdfOutlined />
            </Button>
          </Tooltip>
        </span>
      );
    }
    return (
      <Row justify="center">
        <Col style={{paddingInline: '15px'}}>
          <h1 className="text-center text-primary">{ state.name }</h1>
          <Descriptions size="small" bordered>
                  <Descriptions.Item label={ <strong>E-mail</strong> }>{ state.email }</Descriptions.Item>
                  <Descriptions.Item label={ <strong>Data de nascimento</strong> }>{ birthdate }</Descriptions.Item>
                  <Descriptions.Item label={ <strong>Identidade de gênero</strong> }>{ state.gender_identity || '-' }</Descriptions.Item>
                  <Descriptions.Item label={ <strong>Necessidade especial</strong> }>{ state.special_need || '-' }</Descriptions.Item>
                  <Descriptions.Item label={ <strong>Data de cadastro</strong> }>{ MysqlToBRDate(state.created_at) }</Descriptions.Item>
                  <Descriptions.Item label={ <strong>Última atualização</strong> }>{ MysqlToBRDate(state.updated_at) }</Descriptions.Item>
                  <Descriptions.Item label={ <strong>Telefone</strong> }>
                      { PhoneMask(`${state.phone?.area_code}${state.phone?.number}`)  }
                  </Descriptions.Item>
                  <Descriptions.Item label={ <strong>Currículo</strong> } span={ 2 }>
                    { curriculum }
                  </Descriptions.Item>
                  <Descriptions.Item label={ <strong>línguas extrangeiras</strong> } span={ 3 }>
                    { languages }
                  </Descriptions.Item>
          </Descriptions>
          <br />
          <Descriptions size="small" title="Endereço" bordered>
            <Descriptions.Item label={ <strong>Rua</strong> } span={ 3 }>{ `${state.street}, ${state.number}` }</Descriptions.Item>
            <Descriptions.Item label={ <strong>Cidade</strong> }>{ `${state.city?.name} - ${state.state?.uf}` }</Descriptions.Item>
            <Descriptions.Item label={ <strong>CEP</strong> }>{ MaskCEP(state.zipcode) }</Descriptions.Item>
          </Descriptions>
          <br />
          <Descriptions size="small" title="Dados profissionais" bordered>
            <Descriptions.Item label={ <strong>Vaga escolhida</strong> }>
              { OccupationService.MAP[state.occupation_id].name } { state.occupation_id === OccupationService.LIST.INTERN.id ? (
              <small>- <strong>{ state.current_semester }º Semestre</strong></small>
            ) : null }
            </Descriptions.Item>
            <Descriptions.Item label={ <strong>OAB</strong> }>{ state.oab || '-' }</Descriptions.Item>
            <Descriptions.Item label={ <strong>Unidade federativa da OAB</strong> }>{ state.oab_state?.uf || '-' }</Descriptions.Item>
            <Descriptions.Item label={ <strong>LinkedIn</strong> } span={ 3 }>{ linkedin_url }</Descriptions.Item>
            <Descriptions.Item label={ <strong>Formação acadêmica</strong> } span={ 3 }>
                { academic_titles }
            </Descriptions.Item>
            <Descriptions.Item label={ <strong>Áreas de interesse</strong> } span={ 3 }>
                { interest_areas }
            </Descriptions.Item>
          </Descriptions>
          { previous_jobs }
        </Col>
      </Row>
    );
}


export function PhoneMask(value: any) {
  if (typeof value !== "string") {
    return value;
  }

  return value
    .replace(/[^\d]/g, "")
    .slice(0, 11)
    .replace(/^(\d{2})(\d)/, "($1) $2")
    .replace(/(\d)(\d{4})$/, "$1-$2");
}

import Rest, { Model } from './Rest';

export interface AcademicTitleModel extends Model{
    id?: string,
    institution?: string,
    academic_title_type_id: 1 | 2 | 3 | 4,
    candidate_id?: number,
}

class AcademicTitleService extends Rest {
    resourceable_as: string = 'App\\Models\\AcademicTitle';
    TYPES = {
        1: {name: 'Graduação'},
        2: {name: 'Pós-Graduação stricto sensu'},
        3: {name: 'Pós-Graduação lato sensu'},
        4: {name: 'Especialização'},
    };
}

export default new AcademicTitleService('AcademicTitleService', '/academic-titles');

import{ ChangeEvent, ReactNode } from 'react';
import { Input } from 'antd';
import { FilterLinkable } from '../Interfaces';

export default function TextFilter({ label, link, suffix, prefix, mask } :
    {
        label: string,
        link: FilterLinkable,
        suffix?: ReactNode,
        prefix?: ReactNode,
        mask?: Function,
    }
) {
    function onChange(e: ChangeEvent<HTMLInputElement>) {
        if (typeof mask === 'function') {
            e.target.value = mask(e.target.value);
        }
        link.onChange(e);
    }

    return (
        <div className="ant-row ant-form-item">
            <div className="ant-col ant-form-item-label">
                <label title={ label }>{ label }</label>
            </div>
            <div className="ant-col ant-form-item-control">
                <div className="ant-form-item-control-input">
                    <div className="ant-form-item-control-input-content">
                        <Input
                            name={ link.name }
                            onChange={ onChange }
                            value={ link.value }
                            suffix={ suffix }
                            prefix={ prefix }
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

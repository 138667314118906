import api from './api';
import routes from './../routes/routes';
import { encode, decode } from '../helpers/Base64';
import Config from '../helpers/Config';
import { Model } from './Rest';
import { AxiosError, AxiosResponse } from 'axios';
import EventManager from './EventManager';

class AuthService {
    serviceName: string = 'AuthService';
    userPermissions: string[] = [];
    defaultRedirect: string = '/';
    intentedRoute: null | string = null;
    isUserAuthenticated: boolean = !!this.getUser(true);

    authenticate(data: {email: string, password: string}) {
        return new Promise((resolve, reject) => {
            // api.get('/csrf-cookie').then((res: AxiosResponse) => {
                api.post('/auth/authenticate', data).then((response) => {
                    this.isUserAuthenticated = true;
                    this.setUser(response.data.user);
                    this.setToken(response.data.token);
                    this.userPermissions = response.data.user.permissions.map((p: any) => p.name);
                    // EM.notify(`${this.serviceName}.authenticate`, response, this.intentedRoute);
                    resolve({
                        response: response,
                        redirect: this.intentedRoute || this.defaultRedirect
                    });
                    this.intentedRoute = null;
                }).catch((error: AxiosError) => {
                    reject(error);
                });
            // }).catch((err: AxiosError) => {
            //     reject(err);
            // });
        });
    }

    me() {
        return new Promise((resolve, reject) => {
            api.get('/me').then((res: AxiosResponse) => {
                this.setUser(res.data.user);
                resolve(res);
            })
            .catch((error: AxiosError) => {
                reject(error);
            });
        });
    }

    logout() {
        return new Promise((resolve, reject) => {
            api.post('/auth/logout').then((res) => {
                resolve(res);
                this.forgetUser();
            }).catch((error) => {
                reject(error);
            });
        });
    }

    isAuthenticated () {
        return this.isUserAuthenticated;
    }

    setIntentedRoute (route: string) {
        if (route !== routes.login.path) {
            this.intentedRoute = route || null;
        }
    }

    setUser (user: Model) {
        localStorage.setItem(
            Config('auth.user_index', ''),
            encode(JSON.stringify(user))
        );
        EventManager.notify(`${this.serviceName}.user.set`, user);
    }

    getUser (setPermissions = false) {
        const userData = localStorage.getItem(Config('auth.user_index'));
        if (!userData) {
            return {};
        }
        const user = JSON.parse(
            decode(userData || '')
        );
        if (setPermissions && user.permissions) {
          this.userPermissions = user.permissions.map((p: any) => p.name);
        }
        return user;
    }

    setToken (token: string) {
        localStorage.setItem(
            Config('auth.token_index', ''),
            encode(token)
        );
    }

    getToken () {
        const token = localStorage.getItem(Config('auth.token_index'));
        if (!token) {
          return '';
        }
        return decode(token);
    }

    getUserPermissions () {
        return this.userPermissions;
    }

    forgetUser () {
        this.isUserAuthenticated = false;
        localStorage.removeItem(Config('auth.user_index'));
        localStorage.removeItem(Config('auth.token_index'));
        // EM.notify(`${this.serviceName}.user.forget`, user);
    }
}

export default new AuthService();

// const AuthService = new Auth();

// export default AuthService;

import Rest, { Model } from './Rest';

export interface OccupationModel extends Model{
    id?: string,
    name?: string,
    birthdate?: string,
    gender_identity?: string,
    ind_handicapped?: boolean,
    special_need?: string,
    zipcode?: string,
    street?: string,
    number?: string,
    oab?: string,
    additional_info?: string,
    linkedin_url?: string,
    experiences?: string,
    community_contributions?: string,
    current_semester?: string,
    email_verified_at?: string,
    city_id?: number,
    state_id?: number,
    oab_state_id?: number,
    occupation_id?: number,
    created_at?: string,
    updated_at?: string,
}

class OccupationService extends Rest {
    resourceable_as: string = 'App\\Models\\Occupation';
    MAP = {
        1: {name: 'Advogado'},
        2: {name: 'Estagiário'},
        3: {name: 'Back office'},
    };
    LIST = {
        LAWYER: {name: 'Advogado', id: 1},
        INTERN: {name: 'Estagiário', id: 2},
        BACK_OFFICE: {name: 'Back office', id: 3},
    };
    OPTIONS = [
        {id: 1, name: 'Advogado'},
        {id: 2, name: 'Estagiário'},
        {id: 3, name: 'Back office'},
    ];
}

export default new OccupationService('OccupationService', '/occupations');

import Rest, { Model } from './Rest';

export interface LegalDocModel extends Model{
    id?: string,
    name?: string,
    tab?: string,
    tab_en_us?: string,
    tab_de_de?: string,
    text?: string,
    text_en_us?: string,
    text_de_de?: string,
}

class LegalDocService extends Rest {
    resourceable_as: string = 'App\\Models\\LegalDoc';
}

export default new LegalDocService('LegalDocService', '/legal-docs');

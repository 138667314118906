import { ChangeEvent, ReactNode } from 'react';
import { StateLinkable } from '../Interfaces';
import TextArea from 'antd/lib/input/TextArea';

export default function InputTextarea({ label, link, error, required, className } :
    {
        label: string,
        link: StateLinkable,
        error?: string,
        required?: boolean
        className?: string,
    }
) {
    let errorElement: ReactNode = null;
    let itemClass: string = 'ant-row ant-form-item';
    const labelClass = required ? 'ant-form-item-required' : '';
    function onChange(e: ChangeEvent<HTMLTextAreaElement>) {
        link.onChange(e);
    }
    if (error) {
        itemClass += ' ant-form-item-has-error';
        errorElement = (
            <div className="ant-form-item-explain ant-form-item-explain-error">
                <div>{ error }</div>
            </div>
        );
    }

    if (className) {
        itemClass += ` ${className}`;
    }
    
    return (
        <div className={ itemClass }>
            <div className="ant-col ant-form-item-label">
                <label className={ labelClass } title={ label }>{ label }</label>
            </div>
            <div className="ant-col ant-form-item-control">
                <div className="ant-form-item-control-input">
                    <div className="ant-form-item-control-input-content">
                        <TextArea className="input-textarea"
                            name={ link.name }
                            onChange={ onChange }
                            value={ link.value }
                        />
                    </div>
                </div>
                { errorElement }
            </div>
        </div>
    );
}
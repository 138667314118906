import React from 'react';
import BannersForm from './Form';
import { Button, PageHeader } from 'antd';
import Route from '../../helpers/Route';
import Redirect from '../../helpers/Redirect';
import { AxiosResponse } from 'axios';

function BannersCreate({ params, querystring }: {
    params: Record<string, any>,
    querystring: Record<string, any>,
}) {
    return (
        <div className="page-content">
            <PageHeader className="p-0"
                title="Novo banner"
                extra={ <Button href={ Route('banners') }>Voltar</Button> }
            />
            <BannersForm params={ params } querystring={ querystring } afterSave={ (res: AxiosResponse) => {
                Redirect(Route('banners'));
            } } />
        </div>
    );
}

export default BannersCreate;

import React from 'react';
import { Checkbox as CheckboxAntd } from 'antd';
import { StateLinkable } from '../Interfaces';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

function Checkbox({ label, link, error, checkedOption = true, uncheckedOption = false, className = '' } :
    {
        label: React.ReactNode,
        link: StateLinkable,
        error?: string,
        checkedOption?: any,
        uncheckedOption?: any,
        className?: string,
    }
) {
    function onChange(e: CheckboxChangeEvent) {
        link.onChange({
            target: {
                value: e.target.checked ? checkedOption : uncheckedOption
            }
        });
    }
    return (
        <div className={ `"ant-row ant-form-item ${className}` }>
            <div className="ant-col ant-form-item-control">
                <div className="ant-form-item-control-input">
                    <div className="ant-form-item-control-input-content">
                        <CheckboxAntd checked={ checkedOption === link.value } onChange={ onChange }>
                            { label }
                        </CheckboxAntd>
                    </div>
                </div>
            </div>
        </div>
    );
}

Checkbox.displayName = 'Checkbox';

export default Checkbox;

export default function GetFlattened(path: string, obj: {[key: string]: any}, defaultValue: any = null) {
    const pathSearch = path.split('.');
    var i, size = pathSearch.length, response = obj;

    for (i = 0; i < size; i += 1) {
        if (response.hasOwnProperty(pathSearch[i])) {
            response = response[pathSearch[i]] || defaultValue;
        } else {
            return defaultValue;
        }
    }
    return response;
}

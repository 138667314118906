import { FormEvent } from 'react';
import useForm from '../../hooks/useForm';
import { Button, Col, Row } from 'antd';
import InputText from '../../Components/Form/InputText';
import Form from '../../Components/Form/Form';
import BannerService, { BannerModel } from '../../services/BannerService';
import InputCheckbox from '../../Components/Form/Checkbox';
import InputImage from '../../Components/Form/InputImage';
import { GenericFormProps } from '../../Components/Interfaces';
const Banner: BannerModel = {};

function BannersForm({ params, afterSave }: GenericFormProps) {
    const { state, link, getError, onSubmit, loading } = useForm(
        BannerService,
        params,
        {afterSave},
        Banner,
    );

    function beforeSubmit(e: FormEvent<HTMLFormElement>) {
        const data = new FormData();
        if (state.id) {
            data.set('id', state.id);
        }
        if (state.text) {
            data.set('text', state.text);
        }
        if (state.text_en_us) {
            data.set('text_en_us', state.text_en_us);
        }
        if (state.text_de_de) {
            data.set('text_de_de', state.text_de_de);
        }
        if (state.ind_has_link) {
            data.set('ind_has_link', state.ind_has_link ? '1' : '0');
        }
        if (state.link) {
            data.set('link', state.link);
        }
        if (state.link_en_us) {
            data.set('link_en_us', state.link_en_us);
        }
        if (state.link_de_de) {
            data.set('link_de_de', state.link_de_de);
        }
        if (state.webapp_image.file) {
            data.set('webapp_image', state.webapp_image.file);
        }
        if (state.mobile_image.file) {
            data.set('mobile_image', state.mobile_image.file);
        }
        onSubmit(data);
    }

    function renderLinks() {
        if (!state.ind_has_link) {
            return null;
        }

        return (
            <>
                <InputText
                    label="Link"
                    link={ link('link') }
                    error={ getError('link') }
                    required
                />
                <InputText
                    label="Link (inglês)"
                    link={ link('link_en_us') }
                    error={ getError('link_en_us') }
                    required
                />
                <InputText
                    label="Link (alemão)"
                    link={ link('link_de_de') }
                    error={ getError('link_de_de') }
                    required
                />
            </>
        );
    }

    return (
        <Form onSubmit={ beforeSubmit } loading={ loading }>
            <InputText
                label="Texto"
                link={ link('text') }
                error={ getError('text') }
                required
            />
            <InputText
                label="Texto (inglês)"
                link={ link('text_en_us') }
                error={ getError('text_en_us') }
                required
            />
            <InputText
                label="Texto (alemão)"
                link={ link('text_de_de') }
                error={ getError('text_de_de') }
                required
            />
            <InputCheckbox
                label="Adicionar link ao banner"
                link={ link('ind_has_link') }
                error={ getError('ind_has_link') }
            />
            { renderLinks() }
            <Row gutter={ 15 }>
                <Col>
                    <InputImage
                        label={ <span>Imagem <small>(1920x650)</small></span> }
                        link={ link('webapp_image') }
                        error={ getError('webapp_image') }
                    />
                </Col>
                <Col>
                    <InputImage
                        label={ <span>Imagem mobile<small>(1000x1250)</small></span> }
                        link={ link('mobile_image') }
                        error={ getError('mobile_image') }
                    />
                </Col>
            </Row>
            <Button htmlType="submit" type="primary">Salvar</Button>
        </Form>
    );
}

export default BannersForm;

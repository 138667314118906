import { Card, Spin } from "antd";
import { useEffect, useState } from "react";
import AlertService from "../../services/AlertService";
import OnedriveService from "../../services/OnedriveService";

function IntegrationsOnedrive({ params, querystring }: any) {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (querystring.code) {
            setLoading(true);
            OnedriveService.sendReturnCode(querystring.code).then((res) => {
                AlertService.success('App conectado com sucesso');
            })
            .catch((err) => {
                AlertService.error('Ops, não foi possível conectar o App a sua conta.');
                console.error(err);
            })
            .finally(() => {
                setLoading(false);
            });
        }
    }, []);
    
    return (
        <div className="loading-wrapper">
            { loading ? <div className="loading-wrapper-element"><Spin /></div> : null }
            <Card title="Conectar Onedrive" style={{ maxWidth: 300 }}>
                <a href={ OnedriveService.getAuthUrl() }>Conectar onedrive</a>
            </Card>
        </div>
    );
}

export default IntegrationsOnedrive;

import Page from 'page';
import routes from './routes';
import './middlewares';
import Router from './Router';
import Views from './../pages';

const DOMNode = document.getElementById('root');
const AppRouter = new Router(DOMNode);

AppRouter.setDefaultLayout(Views.DefaultLayout);

for (const key in routes) {
    AppRouter.register(routes[key]);
}

Page.start({
    click: true,
    hashbang: false
});
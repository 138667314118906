import React, { ReactNode } from 'react';
import { PageHeader, Button, Modal, Input } from 'antd';
import useList from '../../hooks/useList';
import LegalDocService, { LegalDocModel } from '../../services/LegalDocService';
import { EditOutlined, DeleteOutlined, ExclamationCircleOutlined  } from '@ant-design/icons';
import Table from '../../Components/Table';
import Route from '../../helpers/Route';

function LegalDocsList(props: any) {
    const {
        collection,
        loading,
        pagination,
        deletingItem,
        queryState,
    //     tableQuery,
        onTableChange,
    //     onChangeSearchField,
        // onSearch,
    //     onTogglePopover,
    //     isPopoverOpen,
        asTokDeleteResource,
        confirmDeleteResource,
        closeModal,
    } = useList(LegalDocService, props.querystring);

    // function onEdit(item) {
    //     window.redirect(Route('storesEdit', {id: item.id}));
    // }

    // const actions = [
    //     {
    //         name: 'Alterar',
    //         description: 'Alterar',
    //         icon: 'documentEdit',
    //         type: 'icon',
    //         onClick: onEdit,
    //     },
    //     {
    //         name: 'Delete',
    //         description: 'Excluir',
    //         icon: 'trash',
    //         type: 'icon',
    //         color: 'danger',
    //         onClick: asTokDeleteResource,
    //     },
    // ];

    let deleteModal: ReactNode = null;
    if (deletingItem !== false) {
        deleteModal = (
            <Modal visible confirmLoading={ loading }
                okText="Excluir"
                cancelText="Cancelar"
                cancelButtonProps={ {disabled: loading} }
                title={ (
                    <span>
                        <ExclamationCircleOutlined className="text-orange" /> Atenção
                    </span>
                ) }
                onOk={ confirmDeleteResource }
                onCancel={ closeModal } >
                Deseja remover o documento <strong>{deletingItem.name}</strong>?
            </Modal>
        );
    }

    const columns = [
        {title: 'ID', dataIndex: 'id', sorter: true, align: 'center', width: 50},
        {title: 'Nome', dataIndex: 'name', sorter: true},
        {title: 'Alterar', dataIndex: '', align: 'center', render: (legalDoc: LegalDocModel) => {
            return <Button href={ Route('legalDocsEdit', {id: legalDoc.id}) } type="primary" shape="circle" icon={ <EditOutlined /> } />
        } },
        {title: 'Excluir', dataIndex: '', align: 'center', render: (legalDoc: LegalDocModel) => {
            return <Button onClick={ asTokDeleteResource.bind(null, legalDoc) } type="ghost" shape="circle" icon={ <DeleteOutlined /> } />
        } },
    ];

    return (
        <div>
            <PageHeader className="p-0 mb-4"
                title="Documentos legais"
                extra={ <Button href={ Route('legalDocsCreate') } type="primary">Novo documento</Button> }
            />
            {/* <Input.Search className="list-input-search" value={ queryState.q || '' } onChange={ onSearch } /> */}
            <Table
                columns={ columns }
                rowKey={ (legalDoc: LegalDocModel) => legalDoc.id }
                collection={ collection }
                pagination={ pagination }
                loading={ loading }
                onChange={ onTableChange }
            />
            { deleteModal }
        </div>
    );
}

export default LegalDocsList;

import { notification } from 'antd';

class AlertService {
    notify(
        title: string | null = null,
        description: string | null = null,
        type: 'success' | 'error' | 'warning' | 'info' = 'info',
        duration: number = 0
    ) {
        notification[type]({
            message: title,
            description,
            duration
        });
    }

    success(title: string | null, description?: string | null, duration = 4.5) {
        this.notify(title, description, 'success', duration);
    }

    error(title: string | null, description?: string | null, duration = 4.5) {
        this.notify(title, description, 'error', duration);
    }

    warning(title: string | null, description?: string | null) {
        this.notify(title, description, 'warning');
    }

    info(title: string | null, description?: string | null) {
        this.notify(title, description, 'info');
    }
}

export default new AlertService();

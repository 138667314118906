import React, { Component} from 'react';
import { Layout, Menu, Avatar, Spin } from 'antd';
import {
  FileImageOutlined,
  UserOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  MailOutlined,
  FileProtectOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import MenuItem from 'antd/lib/menu/MenuItem';
import Footer from './Footer';
import AuthService from '../../services/AuthService';
import page from 'page';
import EventManager from '../../services/EventManager';
import Route from '../../helpers/Route';
import VazLogo from './../../img/vaz-de-almeida-white-logo.svg';
import VazMonogram from './../../img/vaz-de-almeida-white-monogram.svg';
import Config from '../../helpers/Config';

const { Header, Content, Sider } = Layout;
const { SubMenu } = Menu;

export default class DefaultLayout extends Component<{pageContext: {[key: string]: any}}> {
    unsubscribes: (() => void)[] = [];
    state: any = {
        user: AuthService.getUser(),
        gLoading: false,
        collapsed: false,
        collapsedWidth: 80
    };
    componentDidMount() {
      this.unsubscribes.push(
          EventManager.subscribe('gLoading.update', this.listenGLoading),
          EventManager.subscribe('AuthService.user.set', this.onUserSet)
      );
    }
    componentWillUnmount() {
        this.unsubscribes.map(fn => fn());
    }
    listenGLoading = (loading: boolean) => {
        this.setState({ gLoading: loading });
    };
    onUserSet = (user: any) => {
      this.setState({ user });
    };
    onToggleSideMenu = () => {
      this.setState({ collapsed: !this.state.collapsed });
    };
    onSiderBreakpoint = (isMobile: boolean) => {
        const state = {
            collapsedWidth: 80,
            collapsed: false
        }
        if (isMobile) {
            state.collapsed = true;
            state.collapsedWidth = 0;
        }
        this.setState( state );
    };
    logout = () => {
        this.setState({ gLoading: true }, () => {
            AuthService.logout().then(() => {
                page.redirect(Route('login'));
            });
        });
    };
    renderMenu = () => {
      if (!this.state.user) {
        return (
          <div className="d-flex justify-content-center text-white">
            <Spin />
          </div>
        );
      }

      const ind_admin = this.state.user.ind_admin;
      const currentResource = this.props.pageContext.pathname.split('/')[1];
      const userPermissions = AuthService.getUserPermissions();
      const menuItems = [
          {name: 'Candidatos', route: Route('candidates'), icon: <TeamOutlined />, permissionName: 'candidate'},
          {name: 'Banners', route: Route('banners'), icon: <FileImageOutlined />, permissionName: 'banner'},
          {name: 'Newsletter', route: Route('newsletter'), icon: <MailOutlined />, permissionName: 'newsletter'},
          {name: 'Documentos legais', route: Route('legalDocs'), icon: <FileProtectOutlined />, permissionName: 'legal_doc'},
      ];
      return (
        <Menu theme="dark" mode="inline" selectedKeys={ [`/${currentResource}`] }>
          { menuItems.map((item) => {
            if (ind_admin || userPermissions.indexOf(`${item.permissionName}-view-any`) > -1) {
              return (
                <Menu.Item key={ item.route }>
                  <a href={ item.route }>
                    { item.icon } <span>{ item.name }</span>
                  </a>
                </Menu.Item>
              );
            }
            return null;
          }) }
        </Menu>
      );
    };
    renderSidebar = () => {
        const logo = this.state.collapsed
            ? <img className="mx-auto" src={ VazMonogram } width="35" alt={ Config('app.name') } />
            : <img className="mx-auto" src={ VazLogo } width="160" alt={ Config('app.name') } />;
        return (
            <Sider
                onBreakpoint={ this.onSiderBreakpoint }
                breakpoint="lg"
                collapsedWidth={ this.state.collapsedWidth }
                collapsible
                collapsed={ this.state.collapsed }
                trigger={ null }>
                <a href={ Route('home') } className="logo">
                    { logo}
                </a>
                { this.renderMenu() }
            </Sider>
        );
    };
    render() {
        const SideMenuTrigger = this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined;
        return (
            <div className="loading-wrapper">
                { this.state.gLoading ? <div className="loading-wrapper-element"><Spin /></div> : null }
                <Layout style={{ minHeight: '100vh' }}>
                    { this.renderSidebar() }
                    <Layout className="site-layout">
                        <Header className="site-layout-background" style={{ padding: 0 }}>
                            <div className="header-menu-wrapper">
                                <div className="header-menu-trigger-wrapper">
                                    <SideMenuTrigger className="header-menu-trigger" onClick={ this.onToggleSideMenu } />
                                </div>
                                <Menu mode="horizontal">
                                    <SubMenu title={ <Avatar icon={ <UserOutlined /> } size="small" /> }>
                                        <MenuItem onClick={ this.logout }>Sair</MenuItem>
                                    </SubMenu>
                                </Menu>
                            </div>
                        </Header>
                        <Content className="main-content">
                            { this.props.children }
                        </Content>
                        <Footer />
                    </Layout>
                </Layout>
            </div>
        );
    }
}

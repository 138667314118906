import { AxiosError, AxiosResponse } from 'axios';
import qs from 'qs';
import Config from '../helpers/Config';
import api from './api';

class OnedriveService {
    serviceName: string = 'OnedriveService';
    getAuthUrl() {
        return `https://login.live.com/oauth20_authorize.srf?${qs.stringify({
            client_id: Config('integrations.onedrive.app_id_client', ''),
            scope: 'offline_access files.read files.read.all files.readwrite files.readwrite.all offline_access',
            response_type: 'code',
            redirect_uri: Config('integrations.onedrive.redirect_uri', ''),
        })}`;
    }

    sendReturnCode(code: string) {
        return new Promise((resolve, reject) => {
            api.post('/integrations/onedrive/return', { code }).then((res: AxiosResponse) => {
                resolve(res);
            }).catch((error: AxiosError) => {
                reject(error);
            });
        });
    }

    // isAuthenticated () {
    //     return this.isUserAuthenticated;
    // }

    // setIntentedRoute (route: string) {
    //     if (route !== routes.login.path) {
    //         this.intentedRoute = route || null;
    //     }
    // }

    // setUser (user: Model) {
    //     localStorage.setItem(
    //         Config('auth.user_index', ''),
    //         encode(JSON.stringify(user))
    //     );
    //     // EM.notify(`${this.serviceName}.user.set`, user);
    // }

    // getUser () {
    //     const userData = localStorage.getItem(Config('auth.user_index'));
    //     if (!userData) {
    //         return {};
    //     }
    //     return JSON.parse(
    //         decode(userData || '')
    //     );
    // }

    // forgetUser () {
    //     this.isUserAuthenticated = false;
    //     localStorage.removeItem(Config('auth.user_index'));
    //     // EM.notify(`${this.serviceName}.user.forget`, user);
    // }
}

export default new OnedriveService();

// const OnedriveService = new Auth();

// export default OnedriveService;

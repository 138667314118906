import Rest, { Model } from './Rest';

export interface CandidateLanguageModel extends Model{
    id?: string,
    name?: string,
    level: 1 | 2 | 3,
    candidate_id?: number,
}

class CandidateLanguageService extends Rest {
    resourceable_as: string = 'App\\Models\\CandidateLanguage';
    MAP = {
        1: {name: 'Básico'},
        2: {name: 'Intermediário'},
        3: {name: 'Avançado'},
    };
}

export default new CandidateLanguageService('CandidateLanguageService', '/candidate-languages');

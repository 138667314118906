import { useState, useEffect } from 'react';
import { RestInterface } from '../services/Rest';
import { AxiosResponse } from 'axios';

type ApiRelationsResponse = {
    [key: string]: any[]
};

export default function useView<Model>(
    Service: RestInterface,
    params = {id: null},
    options: {
        onDataFetch?: Function
        onRelationsFetch?: Function
        fetchRelations?: Boolean
    } = {},
    initialState: Model,
) : {
    state: Model,
    loading: boolean,
    relations: ApiRelationsResponse,
} {
    const [state, setState]  = useState<Model>(initialState);
    const [relations, setRelations]  = useState({});
    const [loading, setLoading]  = useState(false);

    useEffect(() => {
        function handleFindResponse(res: AxiosResponse, formRelations: any) {
            let data = typeof options.onDataFetch === 'function'
                ? options.onDataFetch(res.data, formRelations !== false ? formRelations.data : formRelations)
                : res.data;
            return data;
        }

        function handleRelationsResponse(res: AxiosResponse) {
            let data = typeof options.onRelationsFetch === 'function'
                ? options.onRelationsFetch(res.data)
                : res.data;
            return data;
        }

        function fetchResource() {
            let calls: any[] = [false, false];
            if (params.id) {
                calls[0] = Service.find(params.id);
            }
            if (options.fetchRelations === true) {
                calls[1] = Service.getFormRelations();
            }

            if (calls[0] || calls[1]) {
                setLoading(true);
                Promise.all(calls)
                .then((results: AxiosResponse[]) => {
                    if (results[0]) {
                        const newState = Object.assign({}, initialState, handleFindResponse(results[0], results[1]));
                        setState(newState);
                    }
                    if (results[1]) {
                        setRelations(
                            handleRelationsResponse(results[1])
                        );
                    }
                    // this.setState(() => {return newState;});
                    setLoading(false);
                })
                .catch(() => {
                    setLoading(false);
                });
            }
        }
        fetchResource();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        state,
        loading,
        relations,
    };
}

import React from 'react';
import ReactDOM from 'react-dom';
import qs from 'qs';
import Page from 'page';

export type RouteItem = {
    title: string;
    path: string;
    component: React.ReactNode;
    layout?: React.ReactNode;
    middleware?: any;
}

export default class Router {
    defaultLayout: null | React.ReactNode = null;
    RootNode: HTMLElement | null;
    constructor(node: HTMLElement | null) {
        this.RootNode = node;
    }

    renderPage(PageComponent: any, Layout: any = this.defaultLayout, title: string = '') {
        return (context: Record<string, any>) => {
            document.title = title;
            const querystring = qs.parse(context.path.split('?')[1]);
            if (Layout) {
                ReactDOM.render(
                    <Layout pageContext={ context } params={ context.params }>
                        <PageComponent pageContext={ context } params={ context.params } querystring={ querystring } />
                    </Layout>,
                    this.RootNode
                );
                return;
            }

            ReactDOM.render(
                <PageComponent pageContext={ context } params={ context.params } querystring={ querystring } />,
                this.RootNode
            );
        };
    }

    register(route: RouteItem) {
        Page(route.path, this.renderPage(route.component, route.layout, route.title));
    }

    setDefaultLayout(Layout: React.ReactNode) {
        this.defaultLayout = Layout;
    }
}

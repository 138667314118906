import { ReactNode } from 'react';
import { PageHeader, Button, Modal, Card, Spin } from 'antd';
import useList from '../../hooks/useList';
import BannerService, { BannerModel } from '../../services/BannerService';
import { EditOutlined, DeleteOutlined, ExclamationCircleOutlined  } from '@ant-design/icons';
import Route from '../../helpers/Route';
import Sortable, { Sort, SortableItem } from '../../Components/Sortable';
import { AxiosError } from 'axios';
import AlertService from '../../services/AlertService';

function BannersList(props: any) {
    const {
        collection,
        loading,
        deletingItem,
        queryState,
        asTokDeleteResource,
        confirmDeleteResource,
        closeModal,
        setCollection,
        setLoading,
        setQueryState,
    } = useList(BannerService, props.querystring, false, {
        initialQuerystring: {$page: 1, $sort: 'position'}
    });

    let deleteModal: ReactNode = null;
    if (deletingItem !== false) {
        deleteModal = (
            <Modal visible confirmLoading={ loading }
                okText="Excluir"
                cancelText="Cancelar"
                cancelButtonProps={ {disabled: loading} }
                title={ (
                    <span>
                        <ExclamationCircleOutlined className="text-orange" /> Atenção
                    </span>
                ) }
                onOk={ confirmDeleteResource }
                onCancel={ closeModal } >
                Deseja remover o banner <strong>{deletingItem.name}</strong>?
            </Modal>
        );
    }

    function onSortBanners(from: number, to: number) {
        setCollection(
            Sort(collection.slice(), from, to)
        );
    }

    function onDropBanners() {
        setLoading(true);
        BannerService.reorder(collection.map((banner: BannerModel, i: number) => {
            return {
                id: banner.id,
                position: i,
            };
        }))
        .then(() => {
            AlertService.success('Banners ordenados');
            setQueryState(queryState);
            setLoading(false);
        })
        .catch((err: AxiosError) => {
            console.error(err);
            setQueryState(queryState);
            setLoading(false);
        });
    }

    return (
        <div>
            <PageHeader className="p-0 mb-4"
                title="Banners"
                extra={ <Button href={ Route('bannersCreate') } type="primary">Novo banner</Button> }
            />
            <div className="loading-wrapper">
                { loading ? <div className="loading-wrapper-element"><Spin /></div> : null }
                <Sortable onMove={ onSortBanners } onDrop={ onDropBanners } tag="ul" className="sortable-ul sortable-ul-horizontal">
                    { collection.map((banner: BannerModel, i) => {
                        return(
                            <SortableItem tag="li" className="sortable-li" id={ banner.id } index={ i } key={ banner.id }>
                                <Card hoverable style={ { cursor: 'default', maxWidth: 240, marginBottom: 20 } }
                                    className="mb-4"
                                    title={ `Posição ${(i + 1)}` }
                                    bodyStyle={ { padding: '0' } }
                                    cover={
                                        <img src={ banner.mobile_image?.info.list.url } width="240" alt={ banner.mobile_image?.name } />
                                    }
                                    actions={ [
                                        <Button href={ Route('bannersEdit', {id: banner.id}) } type="primary" shape="circle" icon={ <EditOutlined /> } />,
                                        <Button onClick={ asTokDeleteResource.bind(null, banner) } type="ghost" shape="circle" icon={ <DeleteOutlined /> } />
                                    ] }
                                >
                                </Card>
                            </SortableItem>
                        );
                    }) }
                </Sortable>
            </div>
            { deleteModal }
        </div>
    );
}

export default BannersList;

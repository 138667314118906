// @ts-nocheck

export default function SetFlattened<T>(path: string, newValue: any, obj: T) {
    const pathSearch = path.split('.');

    var i, laps = pathSearch.length - 1, temp = obj;

    for (i = 0; i < laps; i += 1) {
        temp = temp[pathSearch[i]];
    }
    temp[pathSearch[laps]] = newValue;
    return obj;
}

import Home from "./Home";
import DefaultLayout from "./layout/DefaultLayout";
import Login from "./auth/Login";
import Error404 from "./layout/Error404";
import CandidatesList from './candidate/List';
import CandidatesView from './candidate/View';
import PrintView from './candidate/PrintView';
import BannersList from './banner/List';
import BannersCreate from './banner/Create';
import BannersEdit from './banner/Edit';
import LegalDocsList from './legalDoc/List';
import LegalDocsCreate from './legalDoc/Create';
import LegalDocsEdit from './legalDoc/Edit';
import NewsletterList from './newsletter/List';
import IntegrationOnedrive from './integrations/Onedrive';

const Views = {
    DefaultLayout,
    Home,
    CandidatesList, CandidatesView, PrintView,
    BannersList, BannersCreate, BannersEdit,
    NewsletterList,
    LegalDocsList, LegalDocsCreate, LegalDocsEdit,
    IntegrationOnedrive,
    Login,
    Error404,
};
export default Views;

import { Component, ReactNode } from 'react';
import { StateLinkable } from '../Interfaces';
import { UploadOutlined } from '@ant-design/icons';
import InputFile from './InputFile';
import Config from '../../helpers/Config';
import PicaLib from 'pica';
import { Card, Spin } from 'antd';
import Sortable, { Sort, SortableItem } from '../Sortable';
import { ImageModel } from '../../services/ImageService';
const Pica = PicaLib();
// import Icon from '../Icon';
// import SortableList from '../SortableList';
// import PicaLib from 'pica';

interface InputImageProps {
    // urlField: 'url',
    label: ReactNode,
    max: false | number,
    multiple: boolean,
    sortable: boolean,
    // max: boolean | number,
    maxWidth?: string | number,
    keyField: string,
    orderField: string,
    getImageUrl: (image: ImageModel) => string,
    // imageable_type: string,
    // imageable_id: string | number,
    error?: string,
    // renderCard?: Function,
    link: StateLinkable,
}
interface InputImageState {
    loading: boolean,
    // showSaveOrderButton: boolean,
    // batchBuffer: Array<any>,
    // imageBuffer: Array<any>,
    // errorsBuffer: Array<any>
}

export default class InputImage extends Component<InputImageProps, InputImageState> {
    deletedCount = 0;
    addCount = 0;
    valueBuffer: any[] = [];
    static defaultProps = {
        max: false,
        multiple: false,
        sortable: false,
        maxWidth: 240,
        orderField: 'position',
        keyField: 'id',
        getImageUrl: function InputImageGetImageUrl (image: ImageModel) {
            return image.info.list.url;
        },
    };
    constructor(props: InputImageProps) {
        super(props);
        this.state = {
            loading: false
        };
        // this.addCount = 0;
        // this.valueBuffer = [];
        // this.deletedCount = 0;
    }

    getValue = () : any[] => {
        return Array.isArray(this.props.link.value)
            ? this.props.link.value.slice()
            : this.props.link.value ? [this.props.link.value] : [];
    }

    onChange = (collection: any[]) => {
        const value = Array.isArray(this.props.link.value)
            ? collection
            : collection[0];
        this.props.link.onChange({
            target: { value }
        });
    }

    onSortMove = (from: number, to: number) => {
        this.onChange(
            Sort(this.getValue(), from, to)
        );
    }

    // onItemSortMove = (newCollection) => {
    //     if (typeof this.props.link.onChange === 'function') {
    //         this.props.link.onChange({
    //             target: {value: newCollection}
    //         });
    //     }
    // }

    // onItemSortDrop = (sortedCollection) => {
    //     if (typeof this.props.link.onChange === 'function') {
    //         this.props.link.onChange({
    //             target: {value: sortedCollection}
    //         });
    //     }
    // }

    toggleSelection = (index: number) => {
        const collection = this.getValue();
        collection[index].ind_delete = !collection[index].ind_delete;
        if (collection[index].ind_delete) {
            this.deletedCount += 1;
        } else {
            this.deletedCount -= 1;
        }
        this.onChange(collection);
        // if (typeof this.props.link.onChange === 'function') {
        //     let value = this.props.link.value || [];
        //     value[index].ind_delete = !value[index].ind_delete;
        //     if (value[index].ind_delete) {
        //         this.deletedCount += 1;
        //     } else {
        //         this.deletedCount -= 1;
        //     }
        //     this.props.link.onChange({
        //         target: { value }
        //     });
        // }
    }

    handleOnLoadImage = (file: File, index: number, e: any) => {
        const image = e.target;
        const width = Config('image.admin.w');
        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = image.height * width / image.width;
        Pica.resize(image, canvas).then((resultCanvas) => {
            let base64 = resultCanvas.toDataURL();
            this.valueBuffer.push({
                id: `${index}-${Math.random().toString(36).substring(7)}`,
                file: file,
                base64,
            });
            this.addCount -= 1;
            if (this.addCount === 0 ) {
                this.setState({
                    loading: false
                }, () => {
                    const value = this.props.multiple ? this.getValue() : [];
                    this.onChange(value.concat(this.valueBuffer));
                    this.valueBuffer = [];
                });
            }
        });
    }

    onAddImage = (e: any) => {
        const value = this.getValue();
        const newState = {loading: true};
        let len = (this.props.multiple && this.props.max !== false)
            ? (this.props.max - value.length + this.deletedCount)
            : e.target.files.length;
        if (len > e.target.files.length) {
            len = e.target.files.length;
        }

        for (let i = 0; i < len; i += 1) {
            let file = e.target.files[i];
            if (file.type.match(/image.*/)) {
                this.addCount += 1;
                const fileReader = new FileReader();
                fileReader.onload = (e: any) => {
                    const image = new Image();
                    image.onload = this.handleOnLoadImage.bind(this, file, i);
                    image.src = e.target.result;
                };
                fileReader.readAsDataURL(file);
            }
        }
        e.target.value = null;
        this.setState(newState);
    }

    // renderSortableImages = (item, index) => {
    //     let icon = null;
    //     let iconName = 'trash';
    //     let klass = 'input-image-image';
    //     const value = this.props.link.value || [];
    //     const content = item.base64 ?
    //         <img src={ item.base64 } />
    //         : <img src={ item[this.props.urlField] } />;

    //     if (item.ind_delete === true) {
    //         klass += ' input-image-image-delete';
    //         iconName = 'undo';
    //     }

    //     if (item.ind_delete !== true || this.props.max === false || (value.length - this.deletedCount) < this.props.max) {
    //         icon = (
    //             <div onClick={ this.toggleSelection.bind(this, index) } className="input-image-checkbox">
    //                 <Icon name={ iconName } />
    //             </div>
    //         );
    //     }

    //     return (
    //         <div className={ klass }>
    //             { icon }
    //             { content }
    //         </div>
    //     );
    // }

    renderActionButtons = () => {
        const collection = this.getValue();

        if (this.props.max === false || (collection.length - this.deletedCount) < this.props.max) {
            return (
                <InputFile className="mr-2 mb-3"
                    label={ <span><UploadOutlined /> Escolher imagem</span> }
                    disabled={ this.state.loading }
                    onChange={ this.onAddImage }
                />
            );
        }
    }

    renderImageCard = (image: ImageModel, i: number) => {
        // const isChecked = this.state.batchBuffer.indexOf(image[this.props.keyField]) > -1;
        // if (typeof this.props.renderCard === 'function') {
        //     return this.props.renderCard(
        //         image,
        //         GetFlattened(this.props.urlField || '', image),
        //         this.onChangeCheckbox.bind(this, image[this.props.keyField]),
        //         isChecked,
        //         i
        //     );
        // }
        const src = image.base64 ?? this.props.getImageUrl(image);
            // typeof this.props.getImageUrl === 'function' ? this.props.getImageUrl(image) : this.props.getImageUrl;
        return (
            <Card hoverable
                style={ { maxWidth: this.props.maxWidth } }
                bodyStyle={ { padding: 0 } }
                // onClick={ this.toggleSelection.bind(this, i) }
                // onClick={ this.toggleSelection.bind(this, image[this.props.keyField]) }
                // onClick={ this.onChangeCheckbox.bind(this, image[this.props.keyField]) }
                cover={
                    <img src={ src } alt="Not found" />
                }
                actions={ [
                    // <Checkbox checked={ isChecked } />,
                    <strong>#{ i + 1 }</strong>,
                ] }
            >
            </Card>
        );
        // ========================================
        // let icon = null;
        // let iconName = 'trash';
        // let klass = 'input-image-image';
        // const value = this.props.link.value || [];
        // const content = item.base64 ?
        //     <img src={ item.base64 } />
        //     : <img src={ item[this.props.urlField] } />;

        // if (item.ind_delete === true) {
        //     klass += ' input-image-image-delete';
        //     iconName = 'undo';
        // }

        // if (item.ind_delete !== true || this.props.max === false || (value.length - this.deletedCount) < this.props.max) {
        //     icon = (
        //         <div onClick={ this.toggleSelection.bind(this, index) } className="input-image-checkbox">
        //             <Icon name={ iconName } />
        //         </div>
        //     );
        // }

        // return (
        //     <div className={ klass }>
        //         { icon }
        //         { content }
        //     </div>
        // );
    }

    renderImages = () => {
        if (this.state.loading) {
            return <Spin />;
        }

        const collection = this.getValue();
        if (collection.length === 0) {
            return null;
        }

        if (this.props.multiple && this.props.sortable) {
            return (
                <Sortable onMove={ this.onSortMove } className="inline-list" tag="ul">
                    { collection.map((image, i) => {
                        return (
                            <SortableItem className="inline-li" tag="li" key={ image.id } id={ image.id } index={ i }>
                                { this.renderImageCard(image, i) }
                            </SortableItem>
                        );
                    }) }
                </Sortable>
            );
        }

        return (
            <ul className="inline-list">
                { collection.map((image: ImageModel, i) => {
                    return (
                        <li className="inline-li" key={ image.id }>
                            { this.renderImageCard(image, i) }
                        </li>
                    );
                }) }
            </ul>
        );
    }

    render() {
        let errorElement: ReactNode = null;
        let itemClass: string = 'ant-row ant-form-item';
        if (this.props.error) {
            itemClass += ' ant-form-item-has-error';
            errorElement = (
                <div className="ant-form-item-explain ant-form-item-explain-error mb-2">
                    <div>{ this.props.error }</div>
                </div>
            );
        }
        // let label = null;
        // let help = null;
        // let addButton = null;

        // if (this.props.help.length > 0) {
        //     help = <small className="form-text text-muted mb-2">{ this.props.help }</small>
        // }

        // if (this.props.label) {
        //     label = <span>{ this.props.label }</span>;
        // }
        // let images = this.props.link.value || [];

        // if (this.props.max === false || (images.length - this.deletedCount) < this.props.max) {
        //     addButton = (
        //         <li className="list-inline-item align-middle">
        //             <label className="input-image-add-image mb-4">
        //                 <div><Icon name="camera" /></div>
        //                 { label }
        //                 <input type="file"
        //                     onChange={ this.onAddImage }
        //                     multiple={ this.props.multiple }
        //                     className="d-none"
        //                 />
        //             </label>
        //         </li>
        //     );
        // }

        return (
            <div className={ itemClass }>
                <div className="ant-col ant-form-item-label">
                    <label>{ this.props.label }</label>
                </div>
                <div className="ant-col ant-form-item-control">
                    <div className="ant-form-item-control-input">
                        <div className="ant-form-item-control-input-content">
                            { this.renderActionButtons() }
                            { errorElement }
                            <div>
                                { this.renderImages() }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            // <div className="form-group">
            //     { help }
            //     <ul className="list-inline">
            //         { addButton }
            //         <li className="list-inline-item align-middle">
            //             <SortableList
            //                 onItemMove={ this.onItemSortMove }
            //                 onItemDrop={ this.onItemSortDrop }
            //                 onChange={ this.onChange }
            //                 render={ this.renderSortableImages }
            //                 keyField="id"
            //                 collection={ images }
            //             />
            //         </li>
            //     </ul>
            // </div>
        );
    }
}

// InputImage.displayName = 'InputImage';
// InputImage.defaultProps = {
//     urlField: 'url',
//     max: false,
//     multiple: false,
//     help: '',
// };
// InputImage.propTypes = {
//     link: Types.shape({
//         onChange: Types.func.isRequired,
//         value: Types.array,
//     }).isRequired,
//     label: Types.string,
//     error: Types.string,
//     help: Types.string,
//     autoFocus: Types.bool,
// };

// export default InputImage;

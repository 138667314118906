/**
 * @param str - String to parse.
 */
export default function MysqlToBRDate (str: any) {
    if (typeof str !== 'string') {
        return str;
    }
    return str
        .split('T')[0]
        .replace(/[^\d]/g, '')
        .slice(0, 8)
        .replace(/^(\d{4})(\d{2})(\d{2})/, '$3$2$1')
        .replace(/^(\d{2})(\d{2})(\d{4})/, '$1/$2/$3');
};

import React, { useEffect, useState } from 'react';
import BannersForm from './Form';
import Redirect from '../../helpers/Redirect';
import Route from '../../helpers/Route';
import { Button, PageHeader } from 'antd';
import EventManager from '../../services/EventManager';
import { BannerModel } from '../../services/BannerService';
import { AxiosResponse } from 'axios';

function BannersEdit({ params, querystring }: {
    params: Record<string, any>,
    querystring: Record<string, any>,
}) {
    const [banner, setBanner] = useState<BannerModel>({});
    function listenFind(res: AxiosResponse) {
        setBanner(res.data);
    }
    useEffect(() => {
        const unsubscribe: Function = EventManager.subscribe('BannerService.find', listenFind);
        return () => {
            unsubscribe();
        };
    });

    return (
        <div className="page-content">
            <PageHeader className="p-0 mb-3" title={ banner.name }
                extra={ <Button href={ Route('banners') }>Voltar</Button> }
            />
            <BannersForm params={ params } querystring={ querystring } afterSave={ () => {
                Redirect(Route('banners'));
            } } />
        </div>
    );
}

export default BannersEdit;

import React, { ReactNode } from 'react';
import { PageHeader, Button, Modal, Tag, Row, Col, Collapse } from 'antd';
import useList from '../../hooks/useList';
import NewsletterService, { NewsletterModel } from '../../services/NewsletterService';
import { DeleteOutlined, ExclamationCircleOutlined  } from '@ant-design/icons';
import Table from '../../Components/Table';
import CheckboxFilter from '../../Components/Filters/CheckboxFilter';
import TextFilter from '../../Components/Filters/TextFilter';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
const InterestFields: Record<string, string> = {
    ind_corporate: 'Corporativo',
    ind_judicial_decisions: 'Decisões Judiciais',
    ind_technology: 'Tecnologia',
    ind_legislation: 'Legislação',
    ind_agribusiness: 'Agronegócios',
    ind_executive_power: 'Poder Executivo',
    ind_work_relationships: 'Relações de Trabalho',
    ind_economic_sectors: 'Setores Econômicos',
    ind_tax: 'Tributário',
    ind_infrastructure_and_construction: 'Infraestrutura e Construção',
    ind_insights: 'Insights',
    ind_overseas_legal_report: 'Overseas Legal Report',
};

function NewslettersList(props: any) {
    const {
        collection,
        loading,
        pagination,
        deletingItem,
        // queryState,
        linkFilter,
        onTableChange,
        asTokDeleteResource,
        confirmDeleteResource,
        closeModal,
    } = useList(NewsletterService, props.querystring);

    let deleteModal: ReactNode = null;
    if (deletingItem !== false) {
        deleteModal = (
            <Modal visible confirmLoading={ loading }
                okText="Excluir"
                cancelText="Cancelar"
                cancelButtonProps={ {disabled: loading} }
                title={ (
                    <span>
                        <ExclamationCircleOutlined className="text-orange" /> Atenção
                    </span>
                ) }
                onOk={ confirmDeleteResource }
                onCancel={ closeModal } >
                Deseja remover o newsletter <strong>{deletingItem.name}</strong>?
            </Modal>
        );
    }

    const columns = [
        {title: 'ID', dataIndex: 'id', sorter: true, align: 'center', width: 50},
        {title: 'Nome', dataIndex: 'name', sorter: true},
        {title: 'E-mail', dataIndex: 'email', render: (email: string) => {
            return email;
        } },
        {title: 'Interesses', dataIndex: '', render: (email: string, newsletter: NewsletterModel) => {
            const tags = [];
            for (const field in InterestFields) {
                if (newsletter[field] === true) {
                    tags.push(
                        <Tag key={ `${newsletter.id}-${field}` }>{ InterestFields[field] }</Tag>
                    );
                }
            }
            return (
                <>
                    { tags }
                </>
            );
        } },
        {title: 'Excluir', dataIndex: '', align: 'center', render: (newsletter: NewsletterModel) => {
            return <Button onClick={ asTokDeleteResource.bind(null, newsletter) } type="ghost" shape="circle" icon={ <DeleteOutlined /> } />
        } },
    ];

    function renderFilters() {
        const interestFielters: ReactNode[] = [];
        // if is not colapsed
        for (const field in InterestFields) {
            interestFielters.push(
                <Col key={ field } span="6">
                    <CheckboxFilter
                        label={ InterestFields[field] }
                        link={ linkFilter(field) }
                        checkedOption={ 1 }
                        uncheckedOption={ 0 }
                    />
                </Col>
            );
        }

        return (
            <Collapse>
                <CollapsePanel header="Filtros" key="1">
                    <Row gutter={ 16 }>
                        <Col span="12">
                            <TextFilter
                                label="Filtrar por nome"
                                link={ linkFilter('name', '~') }
                            />
                        </Col>
                        <Col span="12">
                            <TextFilter
                                label="Filtrar por e-mail"
                                link={ linkFilter('email', '~') }
                            />
                        </Col>
                        { interestFielters }
                    </Row>
                </CollapsePanel>
            </Collapse>
        );
    }

    return (
        <div>
            <PageHeader className="p-0 mb-4"
                title="Newsletters"
            />
            { renderFilters() }
            <Table
                columns={ columns }
                rowKey={ (newsletter: NewsletterModel) => newsletter.id }
                collection={ collection }
                pagination={ pagination }
                loading={ loading }
                onChange={ onTableChange }
            />
            { deleteModal }
        </div>
    );
}

export default NewslettersList;

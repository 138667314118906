import api from './api';
import Rest, { Model } from './Rest';

export interface BannerModel extends Model{
    id?: string,
    start_date?: string,
    end_date?: string,
    position?: number,
    ind_has_link?: boolean,
    link?: string,
    link_en_us?: string,
    link_de_de?: string,
    text?: string,
    text_en_us?: string,
    text_de_de?: string,
    // webapp_image?: ImageModel,
    // mobile_image?: ImageModel,
}

class BannerService extends Rest {
    resourceable_as: string = 'App\\Models\\Banner';

    reorder(data: any) {
        data._method = 'PUT';
        return new Promise((resolve, reject) => {
            api.put(`${this.resoruceRoute}/reorder`, data)
            .then((response) => {
                // EM.notify(`${this.serviceName}.reorder`, response);
                resolve(response);
            })
            .catch((error) => {
                // EM.notify(`${this.serviceName}.reorder.error`, error);
                reject(error);
            });
        });
    }
}

export default new BannerService('BannerService', '/banners');

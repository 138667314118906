import useForm from '../../hooks/useForm';
import { Button, Col, Row } from 'antd';
import InputText from '../../Components/Form/InputText';
import Form from '../../Components/Form/Form';
import LegalDocService, { LegalDocModel } from '../../services/LegalDocService';
import { GenericFormProps } from '../../Components/Interfaces';
import InputTextarea from '../../Components/Form/InputTextarea';
import InputWysiwyg from '../../Components/Form/InputWysiwyg';
const LegalDoc: LegalDocModel = {};

function LegalDocsForm({ params, afterSave, querystring }: GenericFormProps) {
    const { state, link, getError, onSubmit, loading } = useForm(
        LegalDocService,
        params,
        {afterSave},
        LegalDoc,
    );

    return (
        <Form onSubmit={ onSubmit.bind(null, state) } loading={ loading }>
            <Row gutter={ 24 }>
                <Col span="24">
                    <InputText
                        label="Identificação"
                        link={ link('name') }
                        error={ getError('name') }
                        required
                    />
                </Col>
                <Col span="6">
                    <InputTextarea
                        label="Aba"
                        link={ link('tab') }
                        error={ getError('tab') }
                        required
                    />
                </Col>
                <Col span="18">
                    <InputWysiwyg className="legal-doc-content-width"
                        label="Texto"
                        link={ link('text') }
                        error={ getError('text') }
                        required
                    />
                </Col>
                <Col span="6">
                    <InputTextarea
                        label="Aba (inglês)"
                        link={ link('tab_en_us') }
                        error={ getError('tab_en_us') }
                    />
                </Col>
                <Col span="18">
                    <InputWysiwyg className="legal-doc-content-width"
                        label="Texto (inglês)"
                        link={ link('text_en_us') }
                        error={ getError('text_en_us') }
                    />
                </Col>
                <Col span="6">
                    <InputTextarea
                        label="Aba (alemão)"
                        link={ link('tab_de_de') }
                        error={ getError('tab_de_de') }
                    />
                </Col>
                <Col span="18">
                    <InputWysiwyg className="legal-doc-content-width"
                        label="Texto (alemão)"
                        link={ link('text_de_de') }
                        error={ getError('text_de_de') }
                    />
                </Col>
            </Row>
            <Button htmlType="submit" type="primary">Salvar</Button>
        </Form>
    );
}

export default LegalDocsForm;

import { CKEditor } from '@ckeditor/ckeditor5-react';
import CustomEditor from 'ckeditor5-custom-build/build/ckeditor';
import { ReactNode } from 'react';
import { StateLinkable } from '../Interfaces';


const editorConfiguration = {
    toolbar: {
        items: [
            'heading',
            '|',
            'bold',
            'italic',
            'strikethrough',
            'link',
            '|',
            'fontColor',
            // 'fontSize',
            'fontFamily',
            '|',
            'bulletedList',
            // 'numberedList',
            'alignment',
            'outdent',
            'indent',
            '|',
            // 'imageUpload',
            // 'blockQuote',
            'insertTable',
            // 'mediaEmbed',
            '|',
            'undo',
            'redo',
        ]
    },
    language: 'pt-br',
    heading: {
        options: [
            { model: 'heading3', view: {name: 'h3', classes: ['ck-h3']}, title: 'Título', class: 'ck-heading-heading3' },
            { model: 'subtitle', view: {name: 'span', classes: ['ck-subtitle']}, title: 'Subtítulo', class: 'ck-heading-subtitle' },
            { model: 'paragraph', view: {name: 'p', classes: ['ck-p']}, title: 'Parágrafo', class: 'ck-heading-paragraph' },
        ],
    },
    fontFamily: {
        options: [
            'Avenir',
            'Lora',
        ]
    },
    image: {
        toolbar: [
            'imageTextAlternative',
            'imageStyle:full',
            'imageStyle:side'
        ]
    },
    table: {
        contentToolbar: [
            'tableColumn',
            'tableRow',
            'mergeTableCells'
        ]
    },
    fontColor: {
      colors: [
				'black',
				'gray',
				'silver',
				{
					color: 'white',
					hasBorder: true
				},
				'maroon',
				'red',
				'purple',
				'fuchsia',
				'green',
				'lime',
				'olive',
				'yellow',
				'navy',
				'blue',
				'teal',
				'aqua',
        {
            color: '#bc2822',
            label: 'VAA - Primária'
        },
        {
            color: '#cc3e3e',
            label: 'VAA - Primária variação 1'
        },
        {
            color: '#ff6363',
            label: 'VAA - Primária variação 2'
        },
        {
            color: '#ff9999',
            label: 'VAA - Rosea'
        },
        {
            color: '#fff2eb'
        },
        {
            color: '#1a1a1a'
        },
			],
			columns: 4,
			documentColors: 12,
			colorPicker: {
				format: 'hex'
			}
    },
};

function InputWysiwyg({ label, link, error, required, className = '' } :
    {
        label?: string,
        link: StateLinkable,
        error?: string,
        required?: boolean,
        className?: string,
    }
) {
    let errorElement: ReactNode = null;
    let itemClass: string = 'ant-row ant-form-item';
    const labelClass = required ? 'ant-form-item-required' : '';

    function onChange(event: any, editor: any) {
        const data = editor.getData();
        link.onChange({
            target: { value: data }
        });
    }

    if (error) {
        itemClass += ' ant-form-item-has-error';
        errorElement = (
            <div className="ant-form-item-explain ant-form-item-explain-error">
                <div>{ error }</div>
            </div>
        );
    }

    if (className) {
        itemClass += ` ${className}`;
    }

    return (
        <div className={ itemClass }>
            <div className="ant-col ant-form-item-label">
                <label className={ labelClass } title={ label }>{ label }</label>
            </div>
            <div className="ant-col ant-form-item-control">
                <div className="ant-form-item-control-input">
                    <div className="ant-form-item-control-input-content">
                        <CKEditor
                            editor={ CustomEditor }
                            data={ link.value }
                            config={ editorConfiguration }
                            // onChange={ onChange }
                            onBlur={ onChange }
                            // onReady={ (editor: any) => {
                            //     // You can store the "editor" and use when it is needed.
                            //     console.log( 'Editor is ready to use!', editor );
                            // } }
                            // onBlur={ ( event: any, editor: any ) => {
                            //     console.log( 'Blur.', editor );
                            // } }
                            // onFocus={ ( event: any, editor: any ) => {
                            //     console.log( 'Focus.', editor );
                            // } }
                        />
                    </div>
                </div>
                { errorElement }
            </div>
        </div>
    );
}

export default InputWysiwyg;

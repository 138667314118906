export default function ObjectFind(list: {[key: string]: any}[], prop: string, value: any) : {
    index: string | number,
    object: Object | null
} {
    for (let i = 0, len = list.length; i < len; i += 1) {
        if (list[i][prop] == value) {
            return {
                index: i,
                object: list[i]
            };
        }
    }
    return { index: -1, object: null };
}

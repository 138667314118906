import Views from './../pages';
import { RouteItem } from './Router';

const routes: { [key: string]: RouteItem } = {
    home: {path: '/', title: 'Dashboard', component: Views.Home},

    candidates: {path: '/candidatos', title: 'Candidatos', component: Views.CandidatesList},
    candidatesPrintView: {path: '/candidatos/:id/imprimir', title: 'Imprimir » Candidato', component: Views.PrintView, layout: null},

    banners: {path: '/banners', title: 'Banners', component: Views.BannersList},
    bannersCreate: {path: '/banners/novo', title: 'Banners » Novo', component: Views.BannersCreate},
    bannersEdit: {path: '/banners/:id/alterar', title: 'Banners » Alterar', component: Views.BannersEdit},

    newsletter: {path: '/newsletter', title: 'Newsletter', component: Views.NewsletterList},

    legalDocs: {path: '/documentos-legais', title: 'Documentos Legais', component: Views.LegalDocsList},
    legalDocsCreate: {path: '/documentos-legais/novo', title: 'Documentos Legais » Novo', component: Views.LegalDocsCreate},
    legalDocsEdit: {path: '/documentos-legais/:id/alterar', title: 'Documentos Legais » Alterar', component: Views.LegalDocsEdit},

    // passwordRecovery: {path: '/autenticacao/recuperar-senha', title: 'Recuperar senha', component: Views.},
    // passwordReset: {path: '/autenticacao/resetar-senha', title: 'Resetar senha', component: Views.Error404},
    login: {path: '/autenticacao/login', title: 'Login', component: Views.Login, layout: null},

    integrationsOneDriveReturn: {path: '/integracoes/onedrive/return', title: 'Integração » Onedrive', component: Views.IntegrationOnedrive},

    "_404": {path: '*', title: 'Página não encontrada', component: Views.Error404, layout: null},
};

export default routes;

import { ChangeEvent, useState } from 'react';
import AuthService from '../../services/AuthService';
import { Card, Layout, Button, Alert } from 'antd';
import Form from '../../Components/Form/Form';
import InputText from '../../Components/Form/InputText';
import InputPassword from '../../Components/Form/InputPassword';
import {
    MailOutlined,
    LockOutlined
} from '@ant-design/icons';
import Footer from '../layout/Footer';
import page from 'page';
import Logo from './../../img/vaz-de-almeida-logo.svg';

function Login() {
    let ErrorElement: null | JSX.Element = null;
    const [data, setData] = useState({
        email: '',
        password: '',
    });
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState<any>({});

    function change(field: string, e: ChangeEvent<HTMLInputElement>) {
        setData({ ...data, [field]: e.target.value });
    }

    function getError(field: string) {
        if (errors[field] !== undefined) {
            for (const prop in errors[field]) {
                return errors[field][prop];
            }
        }
        return null;
    }

    function onSubmit() {
        setLoading(true);
        AuthService.authenticate(data).then((data: any) => {
            setErrors({});
            page.redirect(data.redirect);
        }).catch((err) => {
            setErrors(err.response.data.error_data);
            setLoading(false);
        });
    }

    if (errors.non_field) {
        ErrorElement = <Alert className="mb-3" type="error" message={ errors.non_field } banner />;
    }

    return (
        <Layout style={{ minHeight: '100vh' }} className="auth-layout">
            <div className="text-center mt-3" style={ {marginBottom: '50px'} }>
                <img src={ Logo } width="290" className="" alt="Vaz de Almeida"/>
            </div>
            <Card title="Login" bordered={ false } className="auth-card">
                <Form onSubmit={ onSubmit } loading={ loading }>
                    <InputText
                        prefix={ <MailOutlined /> }
                        label="E-mail"
                        link={ {onChange: change.bind(null, 'email'), value: data.email} }
                        error={ getError('email') }
                        autoComplete="username"
                    />
                    <InputPassword
                        prefix={ <LockOutlined /> }
                        label="Senha"
                        link={ {onChange: change.bind(null, 'password'), value: data.password} }
                        error={ getError('password') }
                        autoComplete="current-password"
                    />
                    { ErrorElement }
                    <Button htmlType="submit" block type="primary">Login</Button>
                </Form>
            </Card>
            <Footer />
        </Layout>
    );
}

export default Login;

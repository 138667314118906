import { AcademicTitleModel } from './AcademicTitleService';
import { CandidateLanguageModel } from './CandidateLanguageService';
import { CandidatePreviousJobModel } from './CandidatePreviousJobService';
import { CityModel } from './CityService';
import { FileModel } from './FileService';
import { InterestAreaModel } from './InterestAreaService';
import { PhoneModel } from './PhoneService';
import Rest, { Model } from './Rest';
import { StateModel } from './StateService';

export interface CandidateModel extends Model{
    id?: string,
    name?: string,
    birthdate?: string,
    gender_identity?: string,
    ind_handicapped?: boolean,
    special_need?: string,
    zipcode?: string,
    street?: string,
    number?: string,
    oab?: string,
    additional_info?: string,
    linkedin_url?: string,
    experiences?: string,
    community_contributions?: string,
    current_semester?: string,
    email_verified_at?: string,
    city?: CityModel,
    city_id?: number,
    state?: StateModel,
    state_id?: number,
    oab_state?: StateModel,
    oab_state_id?: number,
    occupation_id: 1 | 2 | 3,
    created_at?: string,
    updated_at?: string,
    languages: CandidateLanguageModel[]
    academic_titles: AcademicTitleModel[]
    previous_jobs: CandidatePreviousJobModel[]
    interest_areas: InterestAreaModel[]
    curriculum?: FileModel,
    phone?: PhoneModel,
}

class CandidateService extends Rest {
    resourceable_as: string = 'App\\Models\\Candidate';
    INTEREST_GROUP_MAP: Record<string, number> = {
        1: 1,
        2: 1,
        3: 2,
    };
}

export default new CandidateService('CandidateService', '/candidates');

import { Table as AntTable } from 'antd';

export type TableProps = {
    loading: boolean,
    columns: any,
    rowKey: any,
    collection: any[],
    pagination: any,
    onChange: any
    // onChange: (
    //     pagination: TablePaginationConfig,
    //     filters: Record<string, (string | number | boolean)[] | null>,
    //     sorter: SorterResult<any>,
    //     extra: TableCurrentDataSource<T>
    // ) => void,
}

export default function Table({
    loading,
    columns,
    rowKey,
    collection,
    pagination,
    onChange,
} : TableProps) {
    return (
        <AntTable className="mt-4" size="small" bordered
            loading={ loading }
            columns={ columns }
            rowKey={ rowKey }
            dataSource={ collection }
            pagination={ pagination }
            onChange={ onChange }
        />
    );
}

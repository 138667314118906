import { ReactNode, useState } from 'react';
import { PageHeader, Button, Modal, Col, Row, Collapse, Tooltip } from 'antd';
import useList from '../../hooks/useList';
import CandidateService, { CandidateModel } from '../../services/CandidateService';
import {
    LinkedinOutlined,
    ExclamationCircleOutlined,
    EyeOutlined,
    FilePdfOutlined,
    DeleteOutlined,
    PrinterOutlined,
} from '@ant-design/icons';
import Table from '../../Components/Table';
import MysqlToBRDate from '../../helpers/MysqlToBRDate';
import OccupationService from '../../services/OccupationService';
import CandidatesView from './View';
import TextFilter from '../../Components/Filters/TextFilter';
import SelectFilter from '../../Components/Filters/SelectFilter';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import { InterestAreaModel } from '../../services/InterestAreaService';
import ToInteger from '../../helpers/ToInteger';
import CandidateLanguageService, { CandidateLanguageModel } from '../../services/CandidateLanguageService';
import ObjectFind from '../../helpers/ObjectFind';
import AuthService from '../../services/AuthService';
import Route from '../../helpers/Route';

function CandidatesList(props: any) {
    const {
        collection,
        loading,
        pagination,
        queryState,
        deletingItem,
        relations,
        setQueryState,
        onTableChange,
        linkFilter,
        asTokDeleteResource,
        closeModal,
        confirmDeleteResource,
    // } = useList(CandidateService, props.querystring, true);
    } = useList(CandidateService, props.querystring, true, { onRelationFetch });
    const [isModalViewOpen, setModalView] = useState<false | CandidateModel>(false);
    const [languageOpts, setLanguageOpts] = useState<{names: {id?: string, name?: string}[], levels: {id?: string | number, name?: string | number}[]}>({
      names: [],
      levels: []
    });
    const [semesterOpts, setSemesterOpts] = useState<{id: string, name: string}[]>([]);

    function onRelationFetch(relations: Record<string, any>) {
        // if (queryState.occupation_id) {
        //     const index = CandidateService.INTEREST_GROUP_MAP[queryState.occupation_id];
        //     setLanguageOpts(relations.interest_areas[index]);
        // }
        const names: {id?: string, name?: string}[] = [];
        const levels: {id?: string | number, name?: string | number}[] = [];
        relations.languages.map((lang: CandidateLanguageModel) => {
            if (ObjectFind(names, 'id', lang.name).index === -1) {
                names.push({id: lang.name, name: lang.name});
            }
            if (ObjectFind(levels, 'id', lang.level).index === -1) {
                levels.push({id: lang.level, name: CandidateLanguageService.MAP[lang.level].name});
            }
        });
        setLanguageOpts({
            names,
            levels
        });

        setSemesterOpts(relations.semesters.map((s: number) => {
          return {
            id: s,
            name: `${s}º semestre`,
          }
        }));

        return relations;
    }

    function onOccupationIdChange(value: string | number) {
        delete queryState.interestAreas;
        setQueryState(queryState);
        return value;
    }

    function openView(candidate: CandidateModel) {
        setModalView(candidate);
    }

    function closeViewModal() {
        setModalView(false);
    }

    let viewModal: ReactNode = null;
    if (isModalViewOpen !== false) {
        viewModal = (
            <Modal visible onCancel={ closeViewModal } title={ <h1>{ isModalViewOpen.name }</h1> } width="80%" footer={[
                <Button key="submit" type="primary" onClick={ closeViewModal }>
                    Fechar
                </Button>,
            ]}>
                <CandidatesView params={ {id: isModalViewOpen.id} } />
            </Modal>
        );
    }

    let deleteModal: ReactNode = null;
    if (deletingItem !== false) {
        deleteModal = (
            <Modal visible confirmLoading={ loading }
                okText="Excluir"
                cancelText="Cancelar"
                cancelButtonProps={ {disabled: loading} }
                title={ (
                    <span>
                        <ExclamationCircleOutlined className="text-orange" /> Atenção
                    </span>
                ) }
                onOk={ confirmDeleteResource }
                onCancel={ closeModal } >
                Deseja remover o candidato <strong>{deletingItem.name}</strong>?
            </Modal>
        );
    }

    const columns = [
        {title: 'ID', dataIndex: 'id', sorter: true, align: 'center', width: 50},
        {title: 'Nome', dataIndex: 'name', sorter: true, render: (field: string, candidate: CandidateModel) => {
            let linkedin = null;
            let curriculum = null;
            if (candidate.linkedin_url) {
                linkedin = (
                  <Tooltip placement="top" title="Acessa o linkedin">
                    <Button type="link" shape="circle" href={ candidate.linkedin_url } target="_blank" rel="noopener noreferrer">
                        <LinkedinOutlined />
                    </Button>
                  </Tooltip>
                );
            }
            if (candidate.curriculum) {
                curriculum = (
                  <Tooltip placement="top" title="Visualizar currículo">
                    <Button type="link" shape="circle" href={ `${candidate.curriculum.url}?token=${AuthService.getToken()}` } target="_blank" rel="noopener noreferrer">
                      <FilePdfOutlined />
                    </Button>
                  </Tooltip>
                );
            }
            return <span>{ field } { linkedin } { curriculum }</span>;
        }},
        {title: 'E-mail', dataIndex: 'email', sorter: true},
        {title: 'Ocupação', dataIndex: 'occupation_id', sorter: true, render: (field: 1 | 2 | 3) => {
            return OccupationService.MAP[field].name;
        }},
        {title: 'Data de cadastro', dataIndex: 'created_at', sorter: true, render: (field: string) => {
            return MysqlToBRDate(field);
        }},
        // {title: 'Última atualização:', dataIndex: 'updated_at', sorter: true, render: (field: string) => {
        //     return MysqlToBRDate(field);
        // }},
        // {title: 'Alterar', dataIndex: '', align: 'center', render: (candidate: CandidateModel) => {
        //     return <Button href={ Route('candidatesEdit', {id: candidate.id}) } type="primary" shape="circle" icon={ <EditOutlined /> } />
        // } },
        {title: 'Visualizar', dataIndex: '', align: 'center', render: (candidate: CandidateModel) => {
            return <Button onClick={ openView.bind(null, candidate) } type="primary" ghost shape="circle" icon={ <EyeOutlined /> } />
        } },
        {title: 'Imprimir', dataIndex: '', align: 'center', render: (candidate: CandidateModel) => {
            return <Button href={ Route('candidatesPrintView', {id: candidate.id}) } type="primary" ghost shape="circle" icon={ <PrinterOutlined /> } target="_blank" />
        } },
        {title: 'Excluir', dataIndex: '', align: 'center', render: (candidate: CandidateModel) => {
            return <Button onClick={ asTokDeleteResource.bind(null, candidate) } type="ghost" shape="circle" icon={ <DeleteOutlined /> } />
        } },
    ];

    function renderFilters() {
        let semestersFilter: ReactNode = null;
        let interestA: ReactNode = null;
        let languageLeves: ReactNode = null;
        if (queryState.occupation_id) {
            const interestAreasIndex = CandidateService.INTEREST_GROUP_MAP[queryState.occupation_id];
            let interestAreas: InterestAreaModel[] = [];
            if (relations.interest_areas) {
                interestAreas = relations.interest_areas[interestAreasIndex];
            }
            interestA = (
                <Col span="6">
                    <SelectFilter
                        label="Área de interesse"
                        options={ interestAreas }
                        link={ linkFilter('interestAreas', '$id:=:', ToInteger) }
                    />
                </Col>
            );
            if (parseInt(queryState.occupation_id) === OccupationService.LIST.INTERN.id) {
              semestersFilter = (
                    <Col span="6">
                        <SelectFilter
                            label="Semestre"
                            options={ semesterOpts }
                            link={ linkFilter('current_semester') }
                        />
                    </Col>
                );
            }
        }

        // if (true) {
        //     languageLeves = (
        //         <Col span="6">
        //             <SelectFilter
        //                 label="Nível"
        //                 options={ languageOpts.levels }
        //                 link={ linkFilter('languages', '$level:=:') }
        //             />
        //         </Col>
        //     );
        // }

        return (
            <Collapse>
                <CollapsePanel header="Filtros" key="1">
                    <div className="ant-form ant-form-vertical">
                    <Row gutter={ 16 }>
                        <Col span="24">
                            <TextFilter
                                label="Nome"
                                link={ linkFilter('name', '~') }
                            />
                        </Col>
                    </Row>
                    <Row gutter={ 16 }>
                        <Col span="6">
                            <SelectFilter
                                label="Ocupação"
                                options={ OccupationService.OPTIONS }
                                link={ linkFilter('occupation_id', null, ToInteger, onOccupationIdChange) }
                                showSearch={ false }
                            />
                        </Col>
                        { semestersFilter }
                        { interestA }
                    </Row>
                    <Row gutter={ 16 }>
                        <Col span="6">
                            <SelectFilter
                                label="Idioma"
                                options={ languageOpts.names }
                                link={ linkFilter('languages', '$name:=:') }
                            />
                        </Col>
                        { languageLeves }
                    </Row>
                    <Row gutter={ 16 }>
                        <Col span="6">
                            <SelectFilter
                                label="Estado"
                                options={ relations.states || [] }
                                link={ linkFilter('state_id') }
                                showSearch={ false }
                            />
                        </Col>
                        <Col span="6">
                            <SelectFilter
                                label="Cidade"
                                options={ relations.cities || [] }
                                link={ linkFilter('city_id') }
                                getOptionLabel={ item => `${item.name} - ${item.state.uf}` }
                            />
                        </Col>
                    </Row>
                    </div>
                </CollapsePanel>
            </Collapse>
        );
    }

    return (
        <div>
            <PageHeader className="p-0 mb-4"
                title="Candidatos"
            />
            { renderFilters() }
            <Table
                columns={ columns }
                rowKey={ (candidate: CandidateModel) => candidate.id }
                collection={ collection }
                pagination={ pagination }
                loading={ loading }
                onChange={ onTableChange }
            />
            { viewModal }
            { deleteModal }
        </div>
    );
}

export default CandidatesList;

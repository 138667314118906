import { ChangeEvent, ReactNode } from 'react';
import { Input } from 'antd';
import { StateLinkable } from '../Interfaces';

function InputText({ label, link, error, required, suffix, prefix, mask, autoComplete } :
    {
        label: string,
        link: StateLinkable,
        error?: string,
        required?: boolean,
        suffix?: ReactNode,
        prefix?: ReactNode,
        mask?: Function,
        autoComplete?: 'on' | 'off' | 'email' | 'username' | 'new-password' | 'current-password',
    }
) {
    let errorElement: ReactNode = null;
    let itemClass: string = 'ant-row ant-form-item';
    const labelClass = required ? 'ant-form-item-required' : '';
    function onChange(e: ChangeEvent<HTMLInputElement>) {
        if (typeof mask === 'function') {
            e.target.value = mask(e.target.value);
        }
        link.onChange(e);
    }
    if (error) {
        itemClass += ' ant-form-item-has-error';
        errorElement = (
            <div className="ant-form-item-explain ant-form-item-explain-error">
                <div>{ error }</div>
            </div>
        );
    }

    return (
        <div className={ itemClass }>
            <div className="ant-col ant-form-item-label">
                <label className={ labelClass } title={ label }>{ label }</label>
            </div>
            <div className="ant-col ant-form-item-control">
                <div className="ant-form-item-control-input">
                    <div className="ant-form-item-control-input-content">
                        <Input
                            name={ link.name }
                            onChange={ onChange }
                            value={ link.value }
                            suffix={ suffix }
                            prefix={ prefix }
                            autoComplete={ autoComplete }
                        />
                    </div>
                </div>
                { errorElement }
            </div>
        </div>
    );
}

InputText.displayName = 'InputText';

export default InputText;

import { CandidateModel } from './CandidateService';
import Rest, { Model } from './Rest';

export interface NewsletterModel extends Model{
    id?: string,
    name?: string,
    email?: string,
    ind_corporate?: boolean,
    ind_judicial_decisions?: boolean,
    ind_technology?: boolean,
    ind_legislation?: boolean,
    ind_agribusiness?: boolean,
    ind_executive_power?: boolean,
    ind_work_relationships?: boolean,
    ind_economic_sectors?: boolean,
    ind_tax?: boolean,
    ind_infrastructure_and_construction?: boolean,
    ind_insights?: boolean,
    ind_overseas_legal_report?: boolean,
    candidate_id?: number,
    candidate?: CandidateModel,
}

class NewsletterService extends Rest {
    resourceable_as: string = 'App\\Models\\Newsletter';
}

export default new NewsletterService('NewsletterService', '/newsletter');

import 'antd/dist/antd.less'
import './styles/bootstrap.scss';
import {getCLS, getFID, getLCP} from 'web-vitals';
import reportWebVitals from './reportWebVitals';
import Config from './helpers/Config';
import AuthService from './services/AuthService';

require('./routes/index');

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
function sendToGoogleAnalytics({name, delta, value, id} : {
    name: any,
    delta: any,
    value: any,
    id: any,
}) {
    // Assumes the global `gtag()` function exists, see:
    // https://developers.google.com/analytics/devguides/collection/ga4
    // @ts-ignore
    gtag('event', name, {
        event_category: 'Web Vitals',
        event_label: id,
        value: Math.round(name === 'CLS' ? delta * 1000 : delta),
        non_interaction: true,

        // Built-in params:
        // value: delta, // Use `delta` so the value can be summed.
        // Custom params:
        metric_id: id, // Needed to aggregate events.
        metric_value: value, // Optional.
        metric_delta: delta, // Optional.
        // OPTIONAL: any additional params or debug info here.
        // See: https://web.dev/debug-web-vitals-in-the-field/
        // metric_rating: 'good' | 'ni' | 'poor',
        // debug_info: '...',
        // ...
    });
}

if (Config('app.env') === 'production') {
    getCLS(sendToGoogleAnalytics);
    getFID(sendToGoogleAnalytics);
    getLCP(sendToGoogleAnalytics);
} else {
    reportWebVitals(console.log);
}

AuthService.me();

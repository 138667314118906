import qs from 'qs';
import { useState, useEffect } from 'react';
import AlertService from '../services/AlertService';
import Redirect from '../helpers/Redirect';
import { RestInterface } from '../services/Rest';
import { AxiosError, AxiosResponse } from 'axios';
import { SorterResult, TableAction } from 'antd/lib/table/interface';
import { PaginationProps } from 'antd/lib/pagination';

export type QueryStringProps = {
    $page?: number,
    $offset?: number,
    $sort?: string,
    [key: string]: any
}

// export type QueryStringOperands = '~' | '$';

let filterTimeoutId: number | undefined;
// let searchTimeoutId: number | undefined;

function useList(
    Service: RestInterface,
    propsQuerystring: Record<string, any>,
    fetchRelations: boolean = false,
    options: {
        initialQuerystring?: QueryStringProps,
        onRelationFetch?: (relations: Record<string, any>) => Record<string, any>
    } = {}
) {
    const [queryState, setQueryState]  = useState<Record<string, any>>({
        $page: 1, $sort: '-id',
        ...options.initialQuerystring,
        ...propsQuerystring
    });

    const [loading, setLoading]  = useState(false);
    const [deletingItem, setDeleteItem]  = useState<any>(false);
    const [collection, setCollection]  = useState<any[]>([]);
    const [relations, setRelations]  = useState<{[key: string]: any[]}>({});
    const [pagination, setPagination]  = useState({
        current: 1,
        pageSize: 15,
        total: 0,
        position: ['topLeft', 'bottomLeft'],
        showTotal: (total: number, range: number[]) => {
            return `${range[0]}-${range[1]} de ${total}`
        }
    });

    function search(query: Record<string, any>) {
        setLoading(true);
        setQueryState(() => query);
        fetchData(query);
    }

    function fetchData(query: Record<string, any>) {
        setLoading(true);
        Service.get(query)
        .then((res: AxiosResponse) => {
            setCollection(res.data.data);
            setPagination({
                current: res.data.current_page,
                pageSize: parseInt(res.data.per_page, 10),
                total: res.data.total,
                position: ['topLeft', 'bottomLeft'],
                showTotal: pagination.showTotal
            });
            setLoading(false);
        })
        .catch((err: AxiosError) => {
            console.error(err);
            setLoading(false);
        });
    }

    function fetchRelationship() {
        setLoading(true);
        Service.getFormRelations('index')
        .then((res: AxiosResponse) => {
            setRelations(
                typeof options.onRelationFetch === 'function'
                ? options.onRelationFetch(res.data)
                : res.data
            );
            setLoading(false);
        })
        .catch((err: AxiosError) => {
            console.error(err);
            setLoading(false);
        });
    }

    function onTableChange(
        pagination: PaginationProps,
        filters: Object,
        sorter: SorterResult<{}>,
        { action }: { action: TableAction }
    ) {
        const query: QueryStringProps = {
            ...queryState,
            $page: pagination.current,
            $offset: pagination.pageSize,
        };
        switch (action) {
            case 'sort':
                if (sorter.column) {
                    query.$sort = (sorter.order === 'ascend' ? '' : '-') + sorter.field;
                } else {
                    query.$sort = '-id';
                }
                break;
            case 'filter':
                console.log(filters);
                break;
        }

        search(query);
        // fetchData(query);
        Redirect(`${window.location.pathname}?${qs.stringify(query, {encode: false})}`);
    }

    // function onTableChange(query) {
    //     // setTableQuery(Object.assign({}, tableQuery, query));
    //     // const nQueryState = Object.assign({}, queryState, {
    //     //     page: parseInt(query.page.index, 10) + 1,
    //     //     offset: parseInt(query.page.size, 10),
    //     //     sort: `${query.sort.direction === 'asc' ? '' : '-'}${query.sort.field}`,
    //     // });
    //     // Redirect(`${window.location.pathname}?${qs.stringify(nQueryState)}`);
    // }

    // function onChangeSearchField(field, fieldName) {
    //     let query = Object.assign({}, tableQuery, {
    //         page: {
    //             index: 0,
    //             size: tableQuery.page.size,
    //         },
    //         qfield: {
    //             field,
    //             fieldName,
    //         }
    //     });
    //     setTableQuery(query);
    //     setPopoverVisibility(false);
    //     const nQueryState = Object.assign({}, queryState, {
    //         qfield: field,
    //     });
    //     Redirect(`${window.location.pathname}?${qs.stringify(nQueryState)}`);
    // }

    // function onSearch(e) {
        // window.clearTimeout(searchTimeoutId);
        // setLoading(true);
        // const q = e.target.value;
        // let query = Object.assign({}, tableQuery, {
        //     page: {
        //         index: 0,
        //         size: tableQuery.page.size,
        //     },
        //     q
        // });
        // setTableQuery(query);
        // searchTimeoutId = window.setTimeout(() => {
        //     const nQueryState = Object.assign({}, queryState, {
        //         q,
        //     });
        //     Redirect(`${window.location.pathname}?${qs.stringify(nQueryState)}`);
        // }, 320);
    // }

    function changeFilter(field: string, value: any, operand?: string | null) {
        window.clearTimeout(filterTimeoutId);
        setLoading(true);
        if (operand) {
            value = `${operand}${value}`;
        }
        const query = {
            ...queryState,
            $page: 1,
            [field]: value,
        };
        setQueryState(() => query);
        // console.log(field, '=>', query);
        filterTimeoutId = window.setTimeout(() => {
            search(query);
            Redirect(`${window.location.pathname}?${qs.stringify(query, {encode: false})}`);
        }, 320);
    }

    function linkFilter(
        field: string,
        operand: string | null = null,
        hookParse?: ((value: string | number) => string | number) | null,
        hookChange?: ((value: string | number) => string | number)
    ) {
        let value: string | number = queryState[field] || '';
        if (operand && typeof value === 'string') {
            value = value.replace(operand, '');
        }

        if (hookParse) {
            value = hookParse(value);
        }

        return {
            name: field,
            value,
            onChange: (e: any) => {
                let value = e.target.value || '';
                if (hookChange) {
                    value = hookChange(value);
                }
                changeFilter(field, value, operand);
            }
        };
    }

    // function onTogglePopover(e) {
    //     setPopoverVisibility(!isPopoverOpen);
    // }

    function asTokDeleteResource(item: any) {
        setDeleteItem(item || false);
    }

    function closeModal() {
        setDeleteItem(false);
    }

    function confirmDeleteResource() {
        setLoading(true);
        Service.delete(deletingItem.id)
        .then(() => {
            AlertService.success('Registro removido com sucesso');
            setDeleteItem(false);
            fetchData(queryState);
        })
        .catch((error: AxiosError) => {
            if (error.response && error.response.status !== 403) {
                AlertService.error('Ops, não foi possivel excluir este registro.');
            }
            setDeleteItem(false);
            setLoading(false);
        });
    }

    useEffect(() => {
        // setLoading(true);
        // fetchData(queryState);
        search(queryState);
        if (fetchRelations) {
            fetchRelationship();
        }
        // return () => {
        //     window.clearTimeout(searchTimeoutId);
        // };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    // }, [propsQuerystring]);

    return {
        onTableChange,
        // onChangeSearchField,
        // onTogglePopover,
        // onSearch,
        setQueryState,
        changeFilter,
        setDeleteItem,
        asTokDeleteResource,
        confirmDeleteResource,
        closeModal,
        linkFilter,
        setCollection,
        setLoading,
        // tableQuery,
        relations,
        deletingItem,
        collection,
        pagination,
        loading,
        queryState,
    };
}

export default useList;

import{ ReactNode } from 'react';
import { Select } from 'antd';
import { FilterLinkable } from '../Interfaces';

export default function SelectFilter({
    label,
    link,
    options,
    suffix,
    prefix,
    getOptionValue = (item: any) => item.id,
    getOptionLabel = (item: any) => item.name,
    showSearch = true
} : {
    label: string,
    link: FilterLinkable,
    options: Record<string, any>[],
    getOptionValue?: (item: any) => string,
    getOptionLabel?: (item: any) => string,
    showSearch?: boolean,
    suffix?: ReactNode,
    prefix?: ReactNode,
}) {
    function onChange(value: any, option: any) {
        link.onChange({
            target: { value }
        });
    }

    return (
        <div className="ant-row ant-form-item">
            <div className="ant-col ant-form-item-label">
                <label title={ label }>{ label }</label>
            </div>
            <div className="ant-col ant-form-item-control">
                <div className="ant-form-item-control-input">
                    <div className="ant-form-item-control-input-content">
                        <Select allowClear
                            onChange={ onChange }
                            value={ link.value }
                            showSearch={ showSearch }
                            filterOption={ (input: string, option: any) => {
                                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            } }
                        >
                            { (options || []).map((item) => {
                                return (
                                    <Select.Option key={ getOptionValue(item) } value={ getOptionValue(item) }>
                                        { getOptionLabel(item) }
                                    </Select.Option>
                                );
                            }) }
                        </Select>
                    </div>
                </div>
            </div>
        </div>
    );
}

import React from 'react';
import LegalDocsForm from './Form';
import { Button, PageHeader } from 'antd';
import Route from '../../helpers/Route';
import Redirect from '../../helpers/Redirect';
import { AxiosResponse } from 'axios';

function LegalDocsCreate({ params, querystring }: {
    params: Record<string, any>,
    querystring: Record<string, any>,
}) {
    return (
        <div className="page-content">
            <PageHeader className="p-0"
                title="Novo documento"
                extra={ <Button href={ Route('legalDocs') }>Voltar</Button> }
            />
            <LegalDocsForm params={ params } querystring={ querystring } afterSave={ (res: AxiosResponse) => {
                Redirect(Route('legalDocs'));
            } } />
        </div>
    );
}

export default LegalDocsCreate;
